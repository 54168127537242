@media only screen and (min-device-width : 320px) and (max-device-width : 786px) {
    #morale {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
    }

    /* form {
    border:  1px solid #b1b7c3;
    border-radius: 8px;
    width: 96%;
    margin: 2%;
    padding: 8px;
    align-self: center;
  } */

    .form-question {
        margin: 8px 0;
    }

    .form-input {
        margin: 5px;
    }

    .form-rating {
        margin: 5%;
    }

    /* .datatable { */
    /* margin: 5%; */
    /* padding: 2%; */
    /* width: 98%; */
    /* border-top: 1px solid black; */
    /* } */
    .review-row {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        /* height: 100vh; */
    }

    .form-section {
        width: 95%;
        margin-bottom: 20px;
    }

    form {
        border: 1px solid #b1b7c3;
        border-radius: 8px;
        padding: 2%;
        /* position: relative; */
        /* height: 70vh; */
    }

    .custom-label {
        margin-right: 15px;
        width: 100%;
        display: inline;
    }

    .form-area {
        width: 100%;
        height: 6rem;
    }

    .form-submit {
        display: block;
        margin: 10px auto;
    }
}

@media only screen and (min-width : 1024px) {


    #review {
        display: flex;
        flex-direction: column;
        /* justify-content: space-evenly; */
    }

    .three-quarter-view {
        width: 74vw;
    }

    .review-row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 20px;
        margin-bottom: 20px;
        /* height: 100vh; */
    }

    .form-section {
        width: 45%;
    }

    form {
        border: 1px solid #b1b7c3;
        border-radius: 8px;
        padding: 2%;
        /* position: relative; */
        /* height: 70vh; */
    }

    .form-set {
        width: 100%;
    }

    .form-input {
        margin: 5px;
    }

    /* had to use ID since .form-select is a class in bootstrap */
    #reviewStaff {
        width: 20vw;
    }

    .form-text {
        width: 20vw;
    }

    .custom-label {
        margin-right: 15px;
        width: 100%;
        display: inline;
    }

    .form-label {
        margin-right: 15px;
    }

    .form-area {
        width: 100%;
        height: 6rem;
    }

    .form-submit {
        display: block;
        margin: 10px auto;
    }

    .data-section {
        width: 48%;
    }

    .datatable {
        /* height: 90vh; */
        /* overflow-y: scroll;
        border-top: 1px solid black; */
        width: 100%;
        min-width: 40vw;
    }

}

h5 {
    text-align: center;
    padding-bottom: 2rem;
}

fieldset {
    margin-top: 5px;
}


.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        display: none;
    }
}

.powerbi-report {
    margin: 0 auto;
    width: 95vw;
    height: 85vh;
}

.metric {
    padding: 5px;
}

.callout {
    width: 24vw;
    border: 1px solid black;
    border-radius: 5px;
    margin-left: 8px;
    padding: 20px;
    height: 15vh;
}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* .static-metric {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-left: 20%;
  padding-right: 20%;
} */

.card-title {
    font-weight: bold;
    margin: 0;
    text-align: center;
}

.card-value {
    margin: 0;
    font-size: 2rem;
    text-align: center;
}

.card-target {
    margin: 0;

}

.show-alert {
    display: block;
    text-align: center;
    font-weight: 600;
    animation: fadeOut 5s linear 1 forwards;
}

.hide-alert {
    display: none;
    animation: fadeOut 5s linear 1 forwards;
}

.error-dialogue {
    color: rgb(189, 8, 8);
}

.double-ring-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 5%
}

.bottom-border {
    border-bottom: 2px solid #282c34;
    margin-bottom: 15px;
    padding-bottom: 15px
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.divider {
    margin: 20px 0;
    width: 100%;
    justify-self: center;
    border: 1px solid black;
}

.check-ins-reports-container {
    margin: 1% 0
}
