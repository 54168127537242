@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap");
:root {
  --review-pie-chart-exceed-color: #00398f;
  --review-pie-chart-met-color: #2c71c0;
  --review-pie-chart-improve-color: #5296c0;
  --primary-base: #6eba4e;
  --primary-base: #5296c0;
  --primary-dark: #2c71c0;
  --primary-darken: #00398f;
  --primary-darkest: rgb(0, 33, 76);
  --primary-lighter: #91C2DD;
  --primary-lightest: #d8ebf3;
  --border-color: #3e4c5a;
  --loader-color: #36d7b7;
  --loader-background-gradient-color-1: rgba(46, 57, 94, 0.866);
  --loader-background-gradient-color-2: rgba(59, 71, 97, 0.46);
  --loader-background-gradient-color-3: rgba(0,0,0,0.14889705882352944);
  --primary-brand: var(--primary-dark);
  --primary-brand-dark: #6eba4e;
  --primary-brand-darkest: #376951;
  --brand-text-color: rgb(16, 23, 27);
  --brand-text-color-light:rgb(255, 245, 239);
  --brand-text-hover: lighten(var(--brand-text-color), 20%);
  --primary-text-dark: var(--primary-brand-darkest);
  --primary-text-light: var(--primary-lightest);
  --primary-dark-text-prime-theme: var(--primary-darkest);
  --primary-dark-prime-theme: var(--primary-darken);
  --primary-prime-highlight-bg: var(--primary-dark);
  --primary-prime-highlight-hover: var(--primary-dark);
  --primary-prime-light-hover: var(--primary-lightest);
  --gradient-color-1: var(--primary-darkest);
  --gradient-color-2: var(--primary-dark);
  --gradient-color-3: var(--primary-base);
  --subtle-shadow: 1px 1px 2px 1px #2d332b1f;
  font-family: Lato, Helvetica, sans-serif;
  --font-family: Lato, Helvetica, sans-serif;
  --surface-a: #fefefe;
  --surface-b: #6cc24a;
  --surface-c: #003d4c;
  --surface-d: #001516;
  --surface-e: #ffffff;
  --surface-f: #f2682a;
  --text-color: rgb(3, 67, 131);
  --text-color-secondary: #708da9;
  --text-color-light-primary: #7daedb;
  --primary-color: #efefef;
  --primary-color-text: #ffffff;
  --header-font-color: var(--text-color-light-primary);
  --surface-spread-light: color-mix(in hsl, var(--primary-lightest) 40%, #e6e8f0);
  --surface-spread-dark: color-mix(in hsl, var(--primary-dark) 1%, #5a5a5a);
  --surface-base-light: var(--surface-spread-light);
  --surface-base-dark: var(--surface-spread-dark);
  --surface-0: #fdfdff;
  --surface-50: color-mix(in hsl, var(--surface-base-light), var(--surface-base-dark) 5%);
  --surface-100: color-mix(in hsl, var(--surface-base-light), var(--surface-base-dark) 10%);
  --surface-200: color-mix(in hsl, var(--surface-base-light), var(--surface-base-dark) 20%);
  --surface-300: color-mix(in hsl, var(--surface-base-light), var(--surface-base-dark) 30%);
  --surface-400: color-mix(in hsl, var(--surface-base-light), var(--surface-base-dark) 40%);
  --surface-500: color-mix(in hsl, var(--surface-base-light), var(--surface-base-dark) 50%);
  --surface-600: color-mix(in hsl, var(--surface-base-light), var(--surface-base-dark) 60%);
  --surface-700: color-mix(in hsl, var(--surface-base-light), var(--surface-base-dark) 70%);
  --surface-800: color-mix(in hsl, var(--surface-base-light), var(--surface-base-dark) 80%);
  --surface-900: color-mix(in hsl, var(--surface-base-light), var(--surface-base-dark) 90%);
  --gray-50: #f1f4f6;
  --gray-100: #e2e8ee;
  --gray-200: #c6d1dd;
  --gray-300: #a9bbcb;
  --gray-400: #8da4ba;
  --gray-500: #708da9;
  --gray-600: #5a7187;
  --gray-700: #435565;
  --gray-800: #2d3844;
  --gray-900: #161c22;
  --green-500: #6dd849;
  --content-padding: 1.25rem;
  --inline-spacing: 0.5rem;
  --border-radius: 6px;
  --surface-ground: #eff3f8;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dfe7ef;
  --surface-hover: #f6f9fc;
  --focus-ring: 0 0 0 1px #c6b9fd;
  --maskbg: rgba(0, 0, 0, 0.4);
  --highlight-bg: #dfdcfc;
  --highlight-text-color: #7254f3;
  --primary-text-font: "Noto Sans Display", sans-serif;
  --primary-header-font: "Gabarito", sans-serif;
}

.help-button {
  margin-left: auto;
  background: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0);
  font-size: calc(0.25vw + 1rem) !important;
  font-family: var(--primary-header-font) !important;
  font-weight: 700;
  color: var(--surface-200) !important;
}
.help-button:hover {
  color: white !important;
  transition: 0.75s;
  text-shadow: var(--surface-200) 0px 0px 2px;
}

.help-email-button {
  float: right;
  font-size: calc(0.25vw + 0.75rem) !important;
  font-family: var(--primary-header-font) !important;
  font-weight: 600;
}

.help-button-root {
  box-shadow: none !important;
}

.help-dialog {
  max-width: 1000px;
}

.help-center-text {
  text-align: center;
}

.help-dialog-content {
  margin: 2.5%;
}
.help-dialog-content p {
  margin: auto;
  width: 95%;
  font-size: calc(0.35vw + 0.7rem) !important;
  font-family: var(--primary-text-font) !important;
}

.help-topic-header {
  margin: 1.5% 0 0.25% 0;
  font-size: calc(0.5vw + 0.75rem) !important;
  font-family: var(--primary-header-font) !important;
  font-weight: 700;
}

.help-dialog-header {
  font-size: calc(0.75vw + 1.15rem) !important;
  font-family: var(--primary-header-font) !important;
  font-weight: 700;
}/*# sourceMappingURL=help.css.map */