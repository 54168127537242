@import tools
@import variables

$width: 95%

.crazy-class-row-name-to-stand-out
    background-color: #ff11df

    
.data-view-table
    // max-width: 100vw
    width: $width
    margin: auto

    h5
        margin: 1% 0
        padding: 0

.data-table-column-lgtext
    text-wrap: balance
    width: 100%
    max-height: 100px
    overflow-y: scroll
    overflow-x: ellipsis
    scrollbar-width: thin
    
    

.p-datatable
    width: 100%
    // max-width: $width
    border: 1px solid $border-color

.p-datatable-tbody
    @include resp-font(.15vw, .65em)
    // font-size: .5em

.client-column-filter
    flex-wrap: wrap

.p-sortable-column
    padding: 0 2px
    // max-height: 25px

.p-column-title
    width: 100%

.p-row-odd
    background-color: #617c982f

.p-multiselect-trigger
    width: 30px

.p-multiselect-panel
    max-width: 350px

.p-multiselect-header
    padding: 1%
    height: 35px

.p-multiselect-items
    padding-left: 0

.p-multiselect-item
    padding: .25em

.p-multiselect-filter
    padding: 0

// .p-paginator-bottom
//     height: 25px
.wrap
    white-space: normal
    overflow-wrap: break-word !important

.column
    padding: .5%
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

.lg-column
    max-width: 225px

.xtra-lrg-column
    max-width: 300px

.md-column
    max-width: 175px

.sm-column
    max-width: 125px
    margin: auto

.column-filter
    max-height: 40px
    min-width: 100%

.p-multiselect-label
    padding-bottom: 0
    padding-top: 0
    padding-right: 0

.p-multiselect-token
    padding-top: 0
    padding-bottom: 0
