@use "./_variables"
@import tools

// @import theme

// $header-text-shadow: 

.report-section
    display: flex
    flex-direction: column
    align-items: center

.ring-chart-title
    text-align: center
    text-shadow: var(--gray-500) 2px 2px 5px
    border-radius: 5px 5px 0 0
    padding-top: .5%
    margin-bottom: 0
    @include resp-font(.9vw, .65em)

.static-metric-panel
    background: tint-color(teal, 85%)
    // background-color: $primary-bg-subtle
    width: 95vw
    max-width: 960px
    height: 100%
    max-height: 35vh
    border-radius: 5px
    // border: 1px solid dark-gray
    // filter: drop-shadow(grey 2px 2px 15px)
    margin: 1vh

    h5
        text-shadow: var(--gray-500) 2px 2px 5px
        color: white
        background: radial-gradient(circle, var(--blue-900) 0%, var(--blue-400) 100%)
        border-radius: 5px 5px 0 0
        padding: 1%
        @include resp-font(.9vw, .5em)

    margin: auto
    display: flex
    flex-direction: row
    flex-wrap: wrap
    // justify-content: space-around
    justify-content: center
    align-items: center

    #dollar-metrics
        padding: 2%

    #static-ar-cards
        // width: 100%
        height: 100%
        display: flex
        flex-direction: column
        flex-wrap: wrap
        justify-content: space-between
        padding-left: 2em
        padding-right: 2em
        font-weight: 600
        .card-title
            @include resp-font(.3vw, .7em)
        .card-value
            @include resp-font(.45vw, .75em)

    #static-ar-chart
        width: 300px
        height: 300px
        filter: drop-shadow(var(--gray-500) 2px 2px 2px)
        display: flex
        flex-direction: column
        justify-content: center

.chart-bubble-tag
    margin-right: 2px
    display: inline-block
    width: 15px
    height: 15px
    border-radius: 50% / 50%

.content-area
    gap: 5%
    display: flex
    flex-wrap: wrap
    justify-content: center
