@use "./_variables"
:root 
    --primary-bg: var(--primary-dark)
    // --primary-bg:rgb(13, 110, 253)
    // --brand-text-color: #f6fff7
    // --gradient-color-1: var(--primary-bg)
    // --gradient-color-2: #0e67db
    // --gradient-color-3: #0f8ef7
    color-scheme: light

.primary-bg
    background: var(--primary-color)

.brand-text
    // color: var(--brand-text-color) !important
    color: white !important

.no-dec
    text-decoration: none

.brand-gradient
    --first-stop: 6%
    --second-stop: calc(var(--first-stop) + 1.25% )
    --third-stop: calc(var(--first-stop) + 2.5% )
    --fourth-stop: calc(var(--first-stop) + 3.5% )
    --fifth-stop: calc(var(--first-stop) + 4.5% )
    --sixth-stop: calc(var(--first-stop) + 5.5% )

    background: var(--primary-bg)
    background: linear-gradient(141deg, var(--gradient-color-1) 0%, var(--gradient-color-2) 78.77%, var(--gradient-color-3) 100%) 
    // background: linear-gradient(135deg, rgba(192,70,13,1) 5%, rgba(216,94,38,1) var(--first-stop), rgba(0,57,143,1) var(--first-stop), rgba(0,57,143,1) var(--second-stop), rgba(216,94,38,1) var(--second-stop), rgba(234,152,50,1) var(--third-stop), rgba(0,57,143,1) var(--third-stop), rgba(0,57,143,1) var(--fourth-stop), rgba(216,94,38,1) var(--fourth-stop), rgba(234,152,50,1) var(--fifth-stop), rgba(0,57,143,1) var(--fifth-stop), #001263 25%, #2c71c0 85%, rgba(82,150,192,1) 100%)

    