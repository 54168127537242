.mywip-metric-card {
  margin: 0 !important;
  box-shadow: none !important;
}
.mywip-metric-card .p-card-body {
  padding: 0 !important;
}
.mywip-metric-card .p-card-content {
  padding: 0 !important;
}
.mywip-metric-card .p-card-footer {
  padding: 0 !important;
}

.border-right {
  border-right: 2px solid gray;
}/*# sourceMappingURL=mywip.css.map */