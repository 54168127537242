

.mywip-metric-card
    margin: 0 !important
    box-shadow: none !important
    .p-card-body
        padding:0 !important
    .p-card-content
        padding: 0 !important
    .p-card-footer
        padding: 0 !important   

.border-right
    border-right: 2px solid gray
